import type { Product } from '@winestyle/api-client/src/ts/api/catalog/v1/product_pb.js'
import { getConfigJSON } from '@/utils/product-config'

export type ProductCarouselLink = {
  title: string
  href: string
}

const carouselFields: Array<keyof Product.AsObject> = [
  'id',
  'code',
  'pricing',
  'pb_package',
  'feature',
  'social',
  'expertRatesList',
  'name',
  'imagesList',
  'category',
  'characteristicsMap',
  'description'
]

export const productDataForCarousel = (products: Product.AsObject[]) => {
  return products.map(product => Object.fromEntries(carouselFields.map((key) => {
    if (key === 'characteristicsMap') {
      const configCategory = product.category === 'champagnes-and-sparkles' ? 'champagnes' : product.category
      const characteristics = getConfigJSON(configCategory)?.grid.accent
        .flat()
        .map(el => Object.keys(el))
        .flat() ??
        []

      return [key, product[key].filter(el => characteristics.includes(el[0]) || el[0] === 'region') ?? []]
    }

    return [key, product[key]]
  })))
}
