export function useCollections () {
  function getCollectionsForPage (path: string, isMscRegion?: boolean) {
    switch (path) {
      case 'wine':
        return [
          'wine_red_top_100',
          'wine_white_top_100',
          'wine_wine_4_plus_stars',
          'wine_rated_wines',
          'wine_top_under_1000',
          'wine_top_under_3000',
          'wine_new_arrivals',
          'wine_premium',
          'wine_non_alcoholic'
        ]
      case 'champagnes-and-sparkling':
        return [
          'champagnes-and-sparkling_prosecco_top_20',
          'champagnes-and-sparkling_sparkling_4_plus_stars',
          'champagnes-and-sparkling_experts_choice',
          'champagnes-and-sparkling_top_under_1000',
          'champagnes-and-sparkling_top_under_3000',
          'champagnes-and-sparkling_france_top_50',
          'champagnes-and-sparkling_russian',
          'champagnes-and-sparkling_cava',
          'champagnes-and-sparkling_new_arrivals',
          'champagnes-and-sparkling_non_alcoholic'
        ]
      case 'whisky':
        return [
          'whisky_top_under_3000',
          'whisky_top_under_6000',
          'whisky_single_malt_top_20',
          'whisky_gift_ideas',
          'whisky_premium',
          'whisky_scotland_top_20',
          'whisky_ireland_top_20',
          'whisky_new_arrivals',
          'whisky_bourbon'
        ]
      case 'cognac':
        return [
          'cognac_france_top_20',
          'cognac_top_under_6000',
          'cognac_armenia_top_30',
          'cognac_premium',
          'cognac_gift_ideas',
          'cognac_buyers_choice'
        ]
      case 'beer':
        return [
          'beer_top_ratings',
          'beer_germany_top_30',
          'beer_belgium_top_30',
          'beer_craft_top_30',
          'beer_fruit_top_30',
          'beer_unfiltered_top_30',
          'beer_light_top_30',
          'beer_dark_top_30',
          'beer_non-alcoholic'
        ]
      case 'vodka':
        return [
          'vodka_top_30',
          'vodka_alpha_alcohol',
          'vodka_flavoured',
          'vodka_gift_ideas',
          'vodka_premium',
          'vodka_grape',
          'vodka_moonshine'
        ]
      case 'liqueur':
        return [
          'liqueur_for_cocktails',
          'liqueur_tinctures_top_30',
          'liqueur_top_100',
          'liqueur_fruit',
          'liqueur_cream',
          'liqueur_herbal',
          'liqueur_coffee',
          'liqueur_chocolate',
          'liqueur_bitters',
          'liqueur_gift_ideas'
        ]
      case 'cider':
        return [
          'cider_top_50',
          'cider_apple',
          'cider_pear',
          'cider_france_top_30',
          'cider_top_ratings',
          'cider_dry',
          'cider_sweet',
          'cider_new_arrivals'
        ]
      case 'juice':
        return [
          'juice_in_glass',
          'juice_apple',
          'juice_orange',
          'juice_cherry',
          'juice_pineapple',
          'juice_peach',
          'juice_vegetable',
          'juice_tomato',
          'juice_pomegranate'
        ]
      case 'water':
        return [
          'water_still',
          'water_sparkling',
          'water_glass',
          'water_mineral',
          'water_energetic_drinks',
          'water_tea',
          'water_premium',
          'water_morse',
          'water_tonic',
          'water_juice_flavoured',
          'water_cola',
          'water_lemonades'
        ]
      case 'accessory':
        return [
          'accessory_for_wine',
          'accessory_corkscrews',
          'accessory_corks',
          'accessory_coolers',
          'accessory_sets',
          'accessory_decanters',
          'accessory_wine_cabinets',
          'accessory_coravin',
          'accessory_for_bar',
          'accessory_gift_package'
        ]
      case 'glass':
        return [
          'glass_glasses',
          'glass_tumblers',
          'glass_plates',
          'glass_jugs',
          'glass_decanters',
          'glass_shots',
          'glass_sets',
          'glass_handmade'
        ]
      case 'grocery':
        return [
          'grocery_olives_canned_goods',
          'grocery_sauce',
          'grocery_coffee_bean',
          'grocery_bread_cookie',
          'grocery_snacks',
          'grocery_pasta',
          'grocery_meat',
          'grocery_tea',
          'grocery_nuts_dried_fruits',
          'grocery_canned_food'
        ]
      case 'cheese':
        return [
          'cheese_swiss',
          'cheese_russian',
          'cheese_with_mold',
          'cheese_hard',
          'cheese_with_additives',
          'cheese_camembert_and_brie',
          'cheese_parmesan',
          'cheese_sheep_and_goat'
        ]
      case 'chocolate':
        return [
          'chocolate_milk',
          'chocolate_dark',
          'chocolate_bitter',
          'chocolate_belgium',
          'chocolate_bars',
          'chocolate_sugar_free',
          'chocolate_gift_ideas',
          'chocolate_with_filling',
          'chocolate_truffles',
          'chocolate_russia',
          'chocolate_handmade',
          'chocolate_with_nuts'
        ]
      case 'gin':
        return [
          'gin_top_20',
          'gin_gift_ideas',
          'gin_flavored'
        ]
      case 'grappa':
        return [
          'grappa_top_20',
          'grappa_italian',
          'grappa_gift_ideas'
        ]
      case 'brandy':
        return [
          'brandy_top_20',
          'brandy_spanish',
          'brandy_french',
          'brandy_fruit',
          'brandy_aged',
          'brandy_gift_ideas',
          'brandy_hrushovica',
          'brandy_slivovitz'
        ]
      case 'armagnac':
        return [
          'armagnac_top_20',
          'armagnac_with_coffee',
          'armagnac_for_anniversary',
          'armagnac_gift_ideas'
        ]
      case 'tequila':
        return [
          'tequila_mexico_top_20',
          'tequila_golden',
          'tequila_silver',
          'tequila_mezcal'
        ]
      case 'rum':
        return [
          'rum_top_20',
          'rum_cuba_top_20',
          'rum_dominican_republic_top_20',
          'rum_golden',
          'rum_white',
          'rum_dark',
          'rum_aged',
          'rum_gift_ideas'
        ]
      case 'spirit':
        return [
          'spirit_french_cognac',
          'spirit_dominican_rum',
          'spirit_mexican_tequila',
          'spirit_liqueurs_for_cocktails',
          'spirit_fruit_brandy',
          'spirit_gin',
          'spirit_tinctures',
          'spirit_absinthe',
          'spirit_gift_ideas'
        ]
      case 'syrup':
        return [
          'syrup_vanilla',
          'syrup_walnut',
          'syrup_topping',
          'syrup_fruit',
          'syrup_for_coffee',
          'syrup_monin',
          'syrup_for_cocktails'
        ]
      case 'gift-pack':
        return [
          'gift-pack_gift_bags',
          'gift-pack_box',
          'gift-pack_cases',
          'gift-pack_wooden_package',
          'gift-pack_cardboard_package',
          'gift-pack_1_bottle_package',
          'gift-pack_2_bottles_package',
          'gift-pack_3_bottles_package'
        ]
      case 'aperitive':
        return [
          'aperitive_italian',
          'aperitive_french',
          'aperitive_orange',
          'aperitive_russian',
          'aperitive_campari'
        ]
      case 'oil':
        return [
          'oil_olive',
          'oil_extra_virgin',
          'oil_italian',
          'oil_refined',
          'oil_with_additives'
        ]
      case 'vinegar':
        return [
          'vinegar_balsamic',
          'vinegar_wine',
          'vinegar_apple',
          'vinegar_aged',
          'vinegar_italian'
        ]
      case 'bestsellers':
        return [
          'best_by_week',
          'best_red_wine_france',
          'best_sparkling_wines',
          'best_white_wine_italy',
          'best_whisky',
          'best_cognac'
        ]
      case 'corporate':
        return [
          isMscRegion ? 'wine-set_corporate_gifts' : 'interesting_offers',
          'gifts_for_any_occasion'
        ]
      case 'search':
        return [
          'best_you_might_like_it'
        ]
      case 'cart':
        return [
          'best_we_recommend'
        ]
      case 'home':
        return isMscRegion
          ? [
              'promo_discounts',
              'promo_best_offers',
              'grocery_delicacies',
              'cheap_and_best',
              'wine_set',
              'spirit_up_to_1000_in_box'
            ]
          : [
              'best_by_week_alternative',
              'wine_wine_4_plus_stars',
              'best_new',
              'cheap_and_best',
              'wine_set',
              'spirit_up_to_1000_in_box'
            ]
    }

    return []
  }

  return {
    getCollectionsForPage
  }
}
