import { useNuxtApp } from '#app'
import { useAPI, useCacheData, useSharedPromise } from '#imports'
import { productDataForCarousel } from '@/utils/product-carousels'
import { useConfig } from '@/stores/config'

import type { ProductCollection } from '@/modules/nuxt-api/models/ProductCollection'
import type { ProductCollections } from '@/modules/nuxt-api/models/ProductCollections'
import type { ProductCollectionParameters } from '@winestyle/api-client/src/ts/api/catalog/v1/catalog_pb'

type Actions = {
  getHomeCollections: () => Promise<ProductCollection[] | undefined>
  getProductPageCollections: (code: string) => Promise<ProductCollection[] | undefined>
  getProductCollections: (collection: ProductCollectionParameters.AsObject[], category: string) => Promise<ProductCollections[] | undefined >
}

export function useProductCollections (): Actions {
  const nuxtApp = useNuxtApp()
  const api = useAPI()
  const { isMscRegion } = useConfig()
  const { getCollectionsForPage } = useCollections()

  async function getHomeCollections () {
    const { value, addToCache } = await useCacheData<ProductCollection[]>('home-product-collections')

    if (value) {
      return value
    } else {
      const homeCollections = await nuxtApp.runWithContext(() => useSharedPromise('product-collections-home', async () => {
        const collectionsForPage = getCollectionsForPage('home', isMscRegion.value)?.map((el) => {
          return { code: el, limit: 12 }
        })
        return await getProductCollections(collectionsForPage, 'home')
      }))

      if (homeCollections?.length) {
        const homeCollectionsShort = homeCollections.map(
          el => ({ ...el, productsList: productDataForCarousel(el.productsList) })
        )
        await addToCache(homeCollectionsShort)

        return homeCollectionsShort
      }

      return homeCollections
    }
  }

  async function getProductPageCollections (code: string) {
    return await nuxtApp.runWithContext(() => useSharedPromise(['product-collections', code], async () => {
      const { getProductCollectionsForProductPageRequest } = api.productCollection()
      return await getProductCollectionsForProductPageRequest(code)
    }))
  }

  async function getProductCollections (collection: ProductCollectionParameters.AsObject[], category: string) {
    const { value, addToCache } = await useCacheData<ProductCollection[]>(`product-collections-${category}`)

    if (value) {
      return value
    } else if (collection?.length) {
      const productCollections = await nuxtApp.runWithContext(() => useSharedPromise(['product-collections', category], async () => {
        const { getProductCollectionsRequest } = api.productCollection()
        return await getProductCollectionsRequest(collection)
      }))

      if (productCollections?.length) {
        await addToCache(productCollections)
      }

      return productCollections
    }
  }

  return {
    getHomeCollections,
    getProductPageCollections,
    getProductCollections
  }
}
